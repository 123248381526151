<template>
  <div>
    <a-modal v-model="modalVisible" title="能耗分类统计" :width="900" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">关闭</a-button>
      </template>
      <div class="box-container" style="margin-top: 10px;">
        <div class="box-container-inner">
          <div class="table-header">
            <h3 class="table-title"></h3>
          </div>
          <div class="pie" id="pie" style="height: 400px; width: 100%"></div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {
  getEcClassificationQueryListByCondition,
} from "A/energyconsumption";

import ecCommont from "../mixins/ecMixin"
import * as echarts from "echarts";

export default {
  mixins: [areaselect, deptselect, ecCommont],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    ecRecordAllIdAndCodeData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalVisible: false,
      modalBodyStyle: {
        height: '500px',
        overflowY: 'auto',
      },
      dataList: [],

      formData: {
      },
      //这里面的数据属于必填项
      formRules: {
      },
      type:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if (this.showType == 'add') {
        return '新增'
      } else if (this.showType == 'edit') {
        return '修改'
      } else if (this.showType == 'detail') {
        return '详情'
      } else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.resetData();
        this.getTableData();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getTableData() {
      let params = {
        ...this.detailData,
        showChart:'1'
      };
      getEcClassificationQueryListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          this.dataList=[]
          if(res.item && res.item.length>0){
            for(let value of res.item) {
              this.type=value.type
              this.dataList.push(
                  {value:value.meter_real_cost,name:value.classification}
              )
            }
          }
          let that=this
          setTimeout(()=>{
            that.initCharts()
          },500)
        }
      })
    },

    onChange(option) {
      this.formData.host_num = option.replace(/\s*/g, "")
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.formData.userdep_id = ''
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData) {
        if (this.showType == 'edit' || this.showType == 'detail') {
        } else {
          this.resetData()
        }
      }
    },
    initCharts(){
      let chartDom = document.getElementById('pie');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          text: this.type,
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: 'horizontal',
          x:'center',
          y:'bottom',
        },
        series: [
          {
            name: '能耗分类占用比例',
            type: 'pie',
            radius: '70%',
            label : {
              normal : {
                //formatter: '{b}:{c} ({d}%)',
                formatter: '{d}%',
                textStyle : {
                  fontWeight : 'normal',
                  fontSize : 15
                }
              }
            },
            data: this.dataList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>
<style lang="scss" scoped>
.pie{
  pointer-events: auto;
}
</style>