<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }"
                          :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')"
                          v-model="userdepidCascaderSelected"
                          :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll"
                          placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="时段" prop="time_interval" style="width: 25%">
              <a-range-picker v-model="queryParams.time_interval">
              </a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="能耗类型" prop="type" style="width: 25%">
              <a-select v-model="queryParams.type">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(item, index) in allTypes" :key="index" :value="item.type">{{ item.type }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="能耗分类" prop="classification" style="width: 25%">
                <a-select v-model="queryParams.classification">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-for="(item, index) in allClassifications" :key="index" :value="item.classification">{{ item.classification }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <!--              <a-form-model-item label="区域" prop="region" style="width: 25%">-->
              <!--                <a-select v-model="queryParams.region">-->
              <!--                  <a-select-option value="">请选择区域</a-select-option>-->
              <!--                  <a-select-option v-for="(item, index) in ecRegionList" :key="index" :value="item">{{ item }}-->
              <!--                  </a-select-option>-->
              <!--                </a-select>-->
              <!--              </a-form-model-item>-->
              <a-form-model-item label="区域" prop="region" style="width: 25%">
                <a-select :disabled="queryParams.classification===''" v-model="queryParams.region">
                  <a-select-option v-for="(item, index) in ecRegionList" :key="index" :value="item">{{ item }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="位置" prop="position" style="width: 25%">
                <a-select v-model="queryParams.position" :disabled="queryParams.classification===''">
                  <a-select-option v-for="(item, index) in ecPositionAllList" :key="index" :value="item">{{ item }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="计量范围" prop="measuring_range" style="width: 25%">
                <a-input v-model="queryParams.measuring_range"></a-input>
              </a-form-model-item>
              <!--              <a-form-model-item label="能耗类别" prop="category" style="width: 25%">-->
              <!--                <a-select v-model="queryParams.category">-->
              <!--                  <a-select-option value="">请选择能耗类别</a-select-option>-->
              <!--                  <a-select-option v-for="(item, index) in ecCategoryAllList" :key="index" :value="item">{{ item }}-->
              <!--                  </a-select-option>-->
              <!--                </a-select>-->
              <!--              </a-form-model-item>-->
              <a-form-model-item label="能耗类别(可多选)" prop="categoryList" style="width: 25%">
                <a-select :disabled="queryParams.classification===''" mode="multiple" v-model="queryParams.categoryList">
                  <a-select-option v-for="(item, index) in ecCategoryAllList" :key="index" :value="item">{{ item }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
<!--              <a-form-model-item label="仪表名称" prop="meter_name" style="width: 25%">-->
<!--                <a-input v-model="queryParams.meter_name"></a-input>-->
<!--              </a-form-model-item>-->

<!--              <a-form-model-item label="是否收费" prop="fee_enable" style="width: 25%">-->
<!--                <a-select v-model="queryParams.fee_enable">-->
<!--                  <a-select-option value="">请选择是否收费</a-select-option>-->
<!--                  <a-select-option v-for="(item, index) in ecFeeSelectList" :key="index" :value="item">{{ item }}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-model-item>-->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">能耗分类查询</h3>
          <div class="table-btns">
            <!--            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>-->
                        <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
                        <a-button v-if="btnList.includes('生成图表')" @click="showChartModal" style="margin-right: 10px"><a-icon type="export"></a-icon>生成数据图表</a-button>
<!--            <a-button @click="exportModal" style="margin-right: 10px">-->
<!--              <a-icon type="export"></a-icon>-->
<!--              批量导出-->
<!--            </a-button>-->
<!--            <a-button @click="showChartModal" style="margin-right: 10px">-->
<!--              <a-icon type="export"></a-icon>-->
<!--              生成数据图表-->
<!--            </a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :customRow="customRow" :columns="tableColumns" :data-source="tableData"
                 :loading="tableLoading"
                 :scroll="{ x: 2000}"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <!--                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>-->
                <!--                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <detail-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData"
                  @get-operation-result="getTableData"></detail-modal>
    <ShowChart :visible.sync="chartModalVisible" :detailData="chartQueryParams"></ShowChart>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {
  deleteOneEcClassificationQuery,
  getEcClassificationQueryListByCondition,
  exportEcClassificationQueryExcel,
  getAllAllocationEcRecordIdAndCodeListByCondition,
  getAllTypesByUserdep,
  getAllClassificationsByUserdep,
} from "A/energyconsumption";
import DetailModal from "./detailModal.vue";
import ecCommont from "../mixins/ecMixin"
import ShowChart from "./showChart.vue";

export default {
  name: "directories",
  mixins: [deptselect, pagination, ecCommont],
  components: {
    ShowChart,
    DetailModal
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      chartModalVisible: false,
      chartQueryParams: null,
      queryParams: {
        classification: '',
        code: '',
        type: '',
        level: '',
        region: '',
        meter_name: '',
        position: '',
        category: '',
        meter_status: '',
        fee_enable: '',
        time_interval: [],
        regionList: [],
        categoryList: [],
        userdepsid:'',
        measuring_range:''
      },
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '能耗分类',
          dataIndex: 'classification',
          key: 'classification',
          ellipsis: true,
        },
        {
          title: '时段',
          dataIndex: 'time_interval',
          key: 'time_interval',
          ellipsis: true,
        },
        {
          title: '能耗表编号',
          dataIndex: 'code',
          key: 'code',
          ellipsis: true,
        },
        {
          title: '能耗类型',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
        },
        {
          title: '区域',
          dataIndex: 'region',
          key: 'region',
          ellipsis: true,
        },
        {
          title: '仪表名称',
          dataIndex: 'meter_name',
          key: 'meter_name',
          ellipsis: true,
        },
        {
          title: '位置',
          dataIndex: 'position',
          key: 'position',
          ellipsis: true,
        },

        {
          title: '计量范围',
          dataIndex: 'measuring_range',
          key: 'measuring_range',
          ellipsis: true,
        },
        {
          title: '能耗类别',
          dataIndex: 'category',
          key: 'category',
          ellipsis: true,
        },
        {
          title: '是否收费',
          dataIndex: 'fee_enable',
          key: 'fee_enable',
          ellipsis: true,
        },
        {
          title: '实际用量',
          dataIndex: 'meter_real_cost',
          key: 'meter_real_cost',
          ellipsis: true,
        },
        {
          title: '计量范围面积(m2)',
          dataIndex: 'measuring_range_area',
          key: 'measuring_range_area',
          ellipsis: true,
        },
        {
          title: '单位能耗(用度/m2)',
          dataIndex: 'specific_ec',
          key: 'specific_ec',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'operation'},
          width: 70
        },
      ],
      ecPositionAllList: [],
      ecCategoryAllList: [],
      ecRecordAllIdAndCodeList: [],


      copyData: {},
      userdepidCascaderSelected: [],
      deviceCodeList: [],
      equipmentStatusList: [],
      equipmentCategoryMap: {},
      equipmentCategoryList: [],
      equipmentChildCategoryList: [],
      equipmentChildCategoryMap: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      //操作按钮权限
      btnList: [],
      menu: {},
      allTypes:[],
      allClassifications:[]
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "能耗分类查询"
    },
    breadcrumb() {
      const pages = [{name: "设施设备管理", path: ""}]
      pages.push({name: "运行管理", path: ""})
      pages.push({name: "能耗管理", path: ""})
      pages.push({name: this.pageTitle, path: ""})
      return pages
    },
    //时段计算
    query_begin_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[0]) {
        return moment(this.queryParams.time_interval[0]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    query_end_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[1]) {
        return moment(this.queryParams.time_interval[1]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
  },
  watch: {
    userdepidCascaderSelected(val) {
      if (val && val.length) {
        this.queryParams.userdepsid = val[val.length - 1];
      } else {
        this.queryParams.userdepsid = '';
      }
      this.getTypeAndClassifications();
    },
    'queryParams.classification'(val){
      if(!val){
        this.queryParams.region=''
        this.queryParams.position=''
        this.queryParams.categoryList=[]
      }
    },
    'queryParams.type'(val) {
      if (val && val.length) {
        // if (this.queryParams.region === '') {
        //   let values = Object.values(this.ecCategoryList);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     this.ecCategoryAllList = this.ecCategoryAllList.concat(value[val]);
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // } else {
        //   this.ecCategoryAllList = this.ecCategoryList[this.queryParams.region][val]
        // }
      } else {
        // if (this.queryParams.region === '') {
        //   let values = Object.values(this.ecCategoryList);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     let _values = Object.values(value);
        //     for(let _value of _values){
        //       this.ecCategoryAllList = this.ecCategoryAllList.concat(_value);
        //     }
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // } else {
        //   let newObg=this.ecCategoryList[this.queryParams.region];
        //   let values = Object.values(newObg);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     this.ecCategoryAllList = this.ecCategoryAllList.concat(value);
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // }
      }
    },
    'queryParams.region'(val) {
      if (val && val.length) {
        // if (this.queryParams.type === '') {
        //   let newObg=this.ecCategoryList[val];
        //   let values = Object.values(newObg);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     this.ecCategoryAllList=this.ecCategoryAllList.concat(value);
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // } else {
        //   this.ecCategoryAllList = this.ecCategoryList[val][this.queryParams.type]
        // }
        this.ecPositionAllList = this.ecPositionList[val]
      } else {
        // if (this.queryParams.type === '') {
        //   let values = Object.values(this.ecCategoryList);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     let _values = Object.values(value);
        //     for(let _value of _values){
        //       this.ecCategoryAllList=this.ecCategoryAllList.concat(_value);
        //     }
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // } else {
        //   let values = Object.values(this.ecCategoryList);
        //   this.ecCategoryAllList = []
        //   for (let value of values) {
        //     this.ecCategoryAllList=this.ecCategoryAllList.concat(value[this.queryParams.type]);
        //   }
        //   this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
        // }
        let entries = Object.entries(this.ecPositionList);
        this.ecPositionAllList = [];
        for (let [key, value] of entries) {
          this.ecPositionAllList = this.ecPositionAllList.concat(value);
        }
      }
    },
  },
  created() {
    this.menu = getItemFromArrayByKey(this.operationMenuTree, "menuid", this.$route.params.menuid, "children")
    for (let i = 0; i < this.menu.children.length; i++) {//获取菜单页面内操作权限
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.init();
    this.getTableData()
    this.initDeptOptionsAll();
    //获取所有本项目的能耗表编号
    //this.getAllEcRecordIDAndCode();
    this.getTypeAndClassifications();
  },
  methods: {
    init() {
      let values = Object.values(this.ecCategoryList);
      this.ecCategoryAllList = []
      for (let value of values) {
        let _values = Object.values(value);
        for (let _value of _values) {
          this.ecCategoryAllList = this.ecCategoryAllList.concat(_value);
        }
      }
      this.ecCategoryAllList = [...new Set(this.ecCategoryAllList)];
      let entries = Object.entries(this.ecPositionList);
      this.ecPositionAllList = [];
      for (let [key, value] of entries) {
        this.ecPositionAllList = this.ecPositionAllList.concat(value);
      }
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        showChart:'0',
        pagesize: this.pagination.pageSize,
        begin_time: (this.query_begin_time === '' ? new Date().getFullYear()+"-01-01" : this.query_begin_time) + " 00:00:00",
        end_time: this.query_end_time === '' ? moment(new Date()).format('YYYY-MM-DD HH:mm:ss') : this.query_end_time + " 23:59:59",
      };
      getEcClassificationQueryListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item&&res.item.length!==0?res.item.map(value=>({
            ...value,
            time_interval:res.time_interval
          })):[];
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getTypeAndClassifications() {
      let params = {
        ...this.queryParams,
      };
      getAllTypesByUserdep(params).then(res => {
        if (res && res.returncode == '0') {
          this.allTypes = res.item;
        }
      })
      getAllClassificationsByUserdep(params).then(res => {
        if (res && res.returncode == '0') {
          this.allClassifications = res.item;
        }
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = []
      this.$refs.queryForm.resetFields();
    },
    getAllEcRecordIDAndCode() {
      let params = {
        ...this.queryParams,
      };
      getAllAllocationEcRecordIdAndCodeListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          this.ecRecordAllIdAndCodeList = res.item;
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'id', id);
      if (type == 'delete') {
        this.deleteConfirm(id, record)
      } else {
        this.showModal(type, record)
      }
    },
    showModal(type, record) {
      if (type === 'add') {
        this.modalVisible = true
        this.modalShowType = type
      } else if (type === 'detail') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      } else if (type === 'edit') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    exportModal() {
      this.$confirm('你确认要按照所选筛选条件导出数据吗？', {
        type: 'warning',
        centered: true,
      }).then(() => {
        this.exportEcClassification()
      }).catch(() => {
      });
    },
    exportEcClassification() {
      let params = {
        ...this.queryParams,
        // begin_time: this.query_begin_time === '' ? '' : this.query_begin_time + " 00:00:00",
        // end_time: this.query_end_time === '' ? '' : this.query_end_time + " 23:59:59",
        showChart:'1',
        begin_time: (this.query_begin_time === '' ? new Date().getFullYear()+"-01-01" : this.query_begin_time) + " 00:00:00",
        end_time: this.query_end_time === '' ? moment(new Date()).format('YYYY-MM-DD HH:mm:ss') : this.query_end_time + " 23:59:59",
      }
      this.showLoading();
      exportEcClassificationQueryExcel(params).then((res) => {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(() => {
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?', {
        type: 'warning',
        centered: true,
      }).then(() => {
        this.delete(record.id);
      }).catch(() => {

      });
    },
    delete(id) {
      this.showLoading();
      if (id) {
        let params = {
          id
        };
        deleteOneEcClassificationQuery(params).then(res => {
          this.hideLoading();
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    customRow(record, index) {
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData = record
            this.modalShowType = "detail"
            this.modalVisible = true
          },
        },
      }
    },
    showChartModal() {
      if (this.queryParams.userdepsid === '') {
        this.$message({
          message: "请选择一个项目",
          type: "warning",
        });
      } else if (this.queryParams.type === '') {
        this.$message({
          message: "请选择一个能耗类型",
          type: "warning",
        });
      } else {
        this.chartQueryParams = null;
        this.chartQueryParams = {
          ...this.queryParams,
          begin_time: this.query_begin_time === '' ? '' : this.query_begin_time + " 00:00:00",
          end_time: this.query_end_time === '' ? '' : this.query_end_time + " 23:59:59",
        }
        this.chartModalVisible = true
      }
    }
  },
}
</script>
<style scoped>

</style>