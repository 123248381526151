<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1300" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{ showType == 'detail' ? '关闭' : '取消' }}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">
          提交
        </a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules"
                    :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="userdepname">
          <a-input disabled v-model.trim="formData.userdepname"/>
        </a-form-model-item>
        <a-form-model-item label="能耗分类" prop="classification">
          <a-input disabled v-model.trim="formData.classification"/>
        </a-form-model-item>
        <a-form-model-item label="时段" prop="time_interval">
          <a-input disabled v-model.trim="formData.time_interval"/>
        </a-form-model-item>
        <a-form-model-item label="能耗表编号" prop="code">
          <a-input disabled v-model.trim="formData.code"/>
        </a-form-model-item>
        <a-form-model-item label="能耗类型" prop="type">
          <a-input disabled v-model.trim="formData.type"/>
        </a-form-model-item>
        <a-form-model-item label="区域" prop="region">
          <a-input disabled v-model.trim="formData.region"/>
        </a-form-model-item>
        <a-form-model-item label="仪表名称" prop="meter_name">
          <a-input disabled v-model.trim="formData.meter_name"/>
        </a-form-model-item>
        <a-form-model-item label="位置" prop="position">
          <a-input disabled v-model.trim="formData.position"/>
        </a-form-model-item>

        <a-form-model-item label="计量范围" prop="measuring_range">
          <a-input disabled v-model.trim="formData.measuring_range"/>
        </a-form-model-item>
        <a-form-model-item label="能耗类别" prop="category">
          <a-input disabled v-model.trim="formData.category"/>
        </a-form-model-item>
        <a-form-model-item label="是否收费" prop="fee_enable" >
          <a-input disabled v-model.trim="formData.fee_enable"/>
        </a-form-model-item>
        <a-form-model-item label="本次实际用度" prop="meter_real_cost">
          <a-input disabled  v-model.trim="formData.meter_real_cost"/>
        </a-form-model-item>
        <a-form-model-item label="计量范围面积(m2)" prop="measuring_range_area">
          <a-input disabled  v-model.trim="formData.measuring_range_area"/>
        </a-form-model-item>
        <a-form-model-item label="单位能耗(用度/m2)" prop="specific_ec">
          <a-input disabled  v-model.trim="formData.specific_ec"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addEcMeterRecord,
  editEcMeterRecord,
  getOneAllocationRecordById,
} from "A/energyconsumption";

import ecCommont from "../mixins/ecMixin"

export default {
  mixins: [areaselect, deptselect, ecCommont],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    ecRecordAllIdAndCodeData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '380px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        userdep_id: '',
        record_id: '',
        type: '',
        meter_name: '',
        meter_code: '',
        category:'',
        position:'',
        meter_status: '',
        magnification:"",
        remark:"",
        meter_person:'',
        meter_last_time:"",
        meter_time:"",
        interval_days:"",
        meter_last_reading:'',
        meter_reading:'',
        meter_cost:"",
        meter_real_cost:"",
        group_name:'',
        specific_ec:'',
        userdepname:"",
        //month:"",
        region:"",
        code:"",
        measuring_range:"",
    time_interval:''

      },
      //这里面的数据属于必填项
      formRules: {
        // userdep_id: [{required: true, message: '请选择项目'}],
        // classification: [{required: true, message: '请选择能耗分类'}],
        // record_id: [{required: true, message: '请选择能耗表编号'}],
        // type: [{required: true, message: '请选择能耗类型'}],
        // level: [{required: true, message: '请选择级数'}],
        // meter_name: [{required: true, message: '请输入仪表名称'}],
        // meter_code: [{required: true, message: '请输入表号'}],
        // measuring_range: [{required: true, message: '请输入计量范围'}],
        // measuring_range_area: [{required: true, message: '请输入计量范围面积'}],
        // magnification: [{required: true, message: '请输入倍率'}],
        // category: [{required: true, message: '请选择能耗类别'}],
        // region: [{required: true, message: '请选择区域'}],
        // position: [{required: true, message: '请选择位置'}],
        // meter_status: [{required: true, message: '请选择能耗表状态'}],
        // fee_enable: [{required: true, message: '请选择是否收费'}],
      },
      monitorpointList: [],
      ecCategoryAllList:[],

      ecMeterFormAllList: [],
      ecGradeList: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if (this.showType == 'add') {
        return '新增'
      } else if (this.showType == 'edit') {
        return '修改'
      } else if (this.showType == 'detail') {
        return '详情'
      } else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
    'formData.userdep_id'(val) {
      this.formData.monitorpointnum = ''
      for (let item of this.monitorpointList) {
        if (item.userdepid === val) {
          this.formData.monitorpointnum = item.monitorpointnum
        }
      }
    },
    'formData.region'(val){
      if (val && val.length) {
        this.ecCategoryAllList = this.ecCategoryList[val][this.formData.type]
      } else {
        this.ecCategoryAllList = []
      }
    },
    'formData.type'(val){
      if (val && val.length) {
        if(val!==this.detailData.type){
          this.formData.category=''
          this.ecCategoryAllList = []
          if(this.formData.region!==''){
            this.ecCategoryAllList = this.ecCategoryList[this.formData.region][val]
          }
        }
      } else {
        this.formData.category=''
      }
    },
    'formData.record_id'(val){
      if (val) {
        //获取一个record
        this.getOneRecord(val);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    onChange(option) {
      this.formData.host_num = option.replace(/\s*/g, "")
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getMonitorPointNameList() {
      getMonitorPointNameListByCondition({userdepsid: this.userInfo.userdepid}).then(res => {
        if (res && res.returncode == '0') {
          this.monitorpointList = res.item
        }
      })
    },
    getOneRecord(id){
      let params = {
        id:id
      };
      getOneAllocationRecordById(params).then(res => {
        if (res && res.returncode == '0') {
          if(res.item && res.item.length!==0){
              this.formData.type=res.item[0].type;
              this.formData.level=res.item[0].level;
              this.formData.meter_name=res.item[0].meter_name;
              this.formData.meter_code=res.item[0].meter_code;
              this.formData.region=res.item[0].region;
              this.formData.position=res.item[0].position;
              this.formData.type=res.item[0].type;
              this.formData.category=res.item[0].category;
              this.formData.magnification=res.item[0].magnification;
              this.formData.measuring_range=res.item[0].measuring_range;
              this.formData.measuring_range_area=res.item[0].measuring_range_area;
              this.formData.meter_status=res.item[0].meter_status;
              this.formData.fee_enable=res.item[0].fee_enable;

          }
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.formData.userdep_id = ''
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData) {
        if (this.showType == 'edit' || this.showType == 'detail') {
          this.formData.classification = this.detailData.classification
          this.formData.record_id = this.detailData.record_id
          this.formData.type = this.detailData.type
          this.formData.level = this.detailData.level
          this.formData.meter_name = this.detailData.meter_name
          this.formData.meter_code = this.detailData.meter_code

          this.formData.category = this.detailData.category
          this.formData.measuring_range = this.detailData.measuring_range
          this.formData.measuring_range_area = this.detailData.measuring_range_area
          this.formData.group_name = this.detailData.group_name
          this.formData.position = this.detailData.position
          this.formData.meter_status = this.detailData.meter_status
          this.formData.fee_enable = this.detailData.fee_enable
          this.formData.magnification = this.detailData.magnification

          this.formData.meter_last_reading = this.detailData.meter_last_reading
          this.formData.meter_reading = this.detailData.meter_reading
          this.formData.meter_cost = this.detailData.meter_cost
          this.formData.meter_real_cost = this.detailData.meter_real_cost
          this.formData.remark = this.detailData.remark
          this.formData.meter_person = this.detailData.meter_person
          this.formData.interval_days = this.detailData.interval_days

          this.formData.meter_time = moment(this.detailData.meter_time).format("YYYY-MM-DD HH:mm:ss");
          this.formData.meter_last_time = moment(this.detailData.meter_last_time).format("YYYY-MM-DD HH:mm:ss");

          this.formData.userdepname = this.detailData.userdepname
          this.formData.monitorpointnum = this.detailData.monitorpointnum
          this.formData.specific_ec = this.detailData.specific_ec
          //this.formData.month = this.detailData.month
          this.formData.region = this.detailData.region
          this.formData.code = this.detailData.code
          this.formData.measuring_range = this.detailData.measuring_range
          this.formData.time_interval = this.detailData.time_interval

        } else {
          this.resetData()
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            let params = {
              ...this.formData,
              //userdepsid:this.formData.userdep_id
            };
            if (this.showType == 'add') {
              this.showLoading();
              addEcMeterRecord(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if (this.showType == 'edit') {
              this.showLoading();
              editEcMeterRecord(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>